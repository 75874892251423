
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.AppBarContainer_007f017874aaadf4{background-color:var(--backgroundColor_5ee6c85e3eb4eea3);height:48px;min-height:48px;padding:0 16px;display:flex;align-items:center;border-bottom:1px solid var(--borderBottomColor_cd5bdbb489e03ea7);position:relative;top:0;z-index:1;position:fixed;width:100%}.SectionCenter_1912a442edf80ec1{flex-grow:1;height:32px;display:flex;align-items:center;justify-content:center}@media (min-width:600px){.AppBarContainer_007f017874aaadf4{padding:0 24px}}`;
styleInject(_css)

/** AppBarContainer Props */
export type AppBarContainerCCM = {
  /** AppBarContainer Component Custom Properties */
  '$backgroundColor': string;
  '$borderBottomColor': string;

  /** AppBarContainer Modifier Flags */
  // No modifiers classes found
};
/** Base AppBarContainer component */
export const AppBarContainer: ComponentCreator<AppBarContainerCCM> = createComponentCreator({
  "name": "AppBarContainer",
  "base": "AppBarContainer_007f017874aaadf4",
  "prop": {
    "$backgroundColor": "--backgroundColor_5ee6c85e3eb4eea3",
    "$borderBottomColor": "--borderBottomColor_cd5bdbb489e03ea7"
  },
  "mod": {}
});


/** SectionCenter Props */
export type SectionCenterCCM = {
  /** SectionCenter Component Custom Properties */
  // No custom properties found

  /** SectionCenter Modifier Flags */
  // No modifiers classes found
};
/** Base SectionCenter component */
export const SectionCenter: ComponentCreator<SectionCenterCCM> = createComponentCreator({
  "name": "SectionCenter",
  "base": "SectionCenter_1912a442edf80ec1",
  "prop": {},
  "mod": {}
});

