import {
  createReactPermissionBindings,
  PermissionsNeededByProvider
} from 'packages/permissions/react';

export const {
  usePermissions,
  Permissions,
  PermissionGroup,
  PermissionMatch,
  PermissionProvider
} = createReactPermissionBindings({
  // A mapping of permission names to API features.
  // This is the typing definitions too.

  showLoginTermsAndConditionsDisclosure:
    'webapi:show_login_terms_and_conditions_disclosure',
  showMastercardDisclosures: 'acp:show_mastercard_disclosures',
  showCardUseDisclosure: 'acp:show_card_use_disclosure',
  showShortAppleDisclosure: 'acp:show_short_apple_disclosure',
  showChangedTermsDepositPrivacyDisclosure:
    'webapi:showChangedTermsDepositPrivacyDisclosure',
  isShowOpenNewAccountButtonBgChanged: 'webapi:show_open_new_account_button_bg'
});

export type PermissionNames = PermissionsNeededByProvider<
  typeof PermissionProvider
>;
