import { createElement } from 'react';
export const IconCard = () => createElement("svg", {
  width: "18px",
  height: "12px",
  viewBox: "0 0 18 12",
  version: "1.1"
}, createElement("title", null, "icon_Login_Activate_Card"), createElement("defs", null), createElement("g", {
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Login",
  transform: "translate(-173.000000, -873.000000)",
  fill: "#D9E1E2"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(170.000000, 867.000000)"
}, createElement("g", {
  id: "Group-4"
}, createElement("g", {
  id: "Group-3"
}, createElement("g", {
  id: "icon_Login_Activate_Card",
  transform: "translate(3.000000, 6.000000)"
}, createElement("path", {
  d: "M0,10.0588235 C0,10.9058824 0.741176471,11.6470588 1.58823529,11.6470588 L16.4117647,11.6470588 C17.2588235,11.6470588 18,10.9058824 18,10.0588235 L18,4.23529412 L0,4.23529412 L0,10.0588235 L0,10.0588235 Z",
  id: "Shape"
}), createElement("path", {
  d: "M16.4117647,0 L1.58823529,0 C0.741176471,0 0,0.741176471 0,1.58823529 L0,3.17647059 L18,3.17647059 L18,1.58823529 C18,0.741176471 17.2588235,0 16.4117647,0 L16.4117647,0 Z",
  id: "Shape"
}))))))));
export const ActivateIconCard = () => createElement("svg", {
  width: "20px",
  height: "14px",
  viewBox: "0 0 20 16",
  version: "1.1"
}, createElement("title", null, "icon_Login_Activate_Card"), createElement("desc", null, "Created with Sketch."), createElement("defs", null), createElement("g", {
  id: "Updates-Carl-02/17",
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Login",
  transform: "translate(-173.000000, -873.000000)",
  fill: "#D9E1E2"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(170.000000, 867.000000)"
}, createElement("g", {
  id: "Group-4"
}, createElement("g", {
  id: "Group-3"
}, createElement("g", {
  id: "icon_Login_Activate_Card",
  transform: "translate(3.000000, 6.000000)"
}, createElement("path", {
  d: "M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z",
  fill: "#1F336B"
}))))))));
export const IconPhone = () => createElement("svg", {
  width: "15px",
  height: "15px",
  viewBox: "0 0 18 18",
  version: "1.1"
}, createElement("title", null, "icon_ContactUs"), createElement("desc", null, "Created with Sketch."), createElement("defs", null), createElement("g", {
  id: "Updates-Carl-02/17",
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Activate",
  transform: "translate(-797.000000, -870.000000)"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(214.000000, 867.000000)"
}, createElement("g", {
  id: "Group-7",
  transform: "translate(580.000000, 0.000000)"
}, createElement("g", {
  id: "icon_ContactUs"
}, createElement("polygon", {
  id: "Shape",
  points: "0 0 24 0 24 24 0 24"
}), createElement("path", {
  d: "M6.62,10.79 C8.06,13.62 10.38,15.93 13.21,17.38 L15.41,15.18 C15.68,14.91 16.08,14.82 16.43,14.94 C17.55,15.31 18.76,15.51 20,15.51 C20.55,15.51 21,15.96 21,16.51 L21,20 C21,20.55 20.55,21 20,21 C10.61,21 3,13.39 3,4 C3,3.45 3.45,3 4,3 L7.5,3 C8.05,3 8.5,3.45 8.5,4 C8.5,5.25 8.7,6.45 9.07,7.57 C9.18,7.92 9.1,8.31 8.82,8.59 L6.62,10.79 L6.62,10.79 Z",
  id: "Shape",
  fill: "#D9E1E2"
})))))));
export const IconRegister = () => createElement("svg", {
  width: "16px",
  height: "16px",
  viewBox: "0 0 18 20",
  version: "1.1"
}, createElement("title", null, "Register_for_Online_Access"), createElement("desc", null, "Created with Sketch."), createElement("defs", null), createElement("g", {
  id: "Updates-Carl-02/17",
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Login",
  transform: "translate(-347.000000, -868.000000)"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(170.000000, 867.000000)"
}, createElement("g", {
  id: "Group-5",
  transform: "translate(174.000000, 0.000000)"
}, createElement("g", {
  id: "Register_for_Online_Access"
}, createElement("polygon", {
  id: "Shape",
  points: "0 0 24 0 24 24 0 24"
}), createElement("path", {
  d: "M19,3 L14.82,3 C14.4,1.84 13.3,1 12,1 C10.7,1 9.6,1.84 9.18,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L19,3 Z M12,3 C12.55,3 13,3.45 13,4 C13,4.55 12.55,5 12,5 C11.45,5 11,4.55 11,4 C11,3.45 11.45,3 12,3 L12,3 Z M10,17 L6,13 L7.41,11.59 L10,14.17 L16.59,7.58 L18,9 L10,17 L10,17 Z",
  id: "Shape",
  fill: "#D9E1E2"
})))))));
export const IconPin = () => createElement("svg", {
  width: "12px",
  height: "18px",
  viewBox: "0 0 14 20",
  version: "1.1"
}, createElement("title", null, "icon_Reload_Locations"), createElement("desc", null, "Created with Sketch."), createElement("defs", null), createElement("g", {
  id: "Updates-Carl-02/17",
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Login",
  transform: "translate(-617.000000, -869.000000)"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(170.000000, 867.000000)"
}, createElement("g", {
  id: "Group-6",
  transform: "translate(442.000000, 0.000000)"
}, createElement("g", {
  id: "icon_Reload_Locations"
}, createElement("path", {
  d: "M12,2 C8.13,2 5,5.13 5,9 C5,14.25 12,22 12,22 C12,22 19,14.25 19,9 C19,5.13 15.87,2 12,2 L12,2 Z M12,11.5 C10.62,11.5 9.5,10.38 9.5,9 C9.5,7.62 10.62,6.5 12,6.5 C13.38,6.5 14.5,7.62 14.5,9 C14.5,10.38 13.38,11.5 12,11.5 L12,11.5 Z",
  id: "Shape",
  fill: "#D9E1E2"
}), createElement("polygon", {
  id: "Shape",
  points: "0 0 24 0 24 24 0 24"
})))))));
export const IconNewAccount = () => createElement("svg", {
  width: "22px",
  height: "17px",
  viewBox: "0 0 22 17",
  version: "1.1"
}, createElement("title", null, "icon_Open_A_New_Account"), createElement("desc", null, "Created with Sketch."), createElement("defs", null), createElement("g", {
  id: "Updates-Carl-02/17",
  stroke: "none",
  strokeWidth: "1",
  fill: "none",
  fillRule: "evenodd"
}, createElement("g", {
  id: "Activate",
  transform: "translate(-549.000000, -870.000000)",
  fill: "#D9E1E2"
}, createElement("g", {
  id: "Group-12",
  transform: "translate(214.000000, 867.000000)"
}, createElement("g", {
  id: "Group-7-Copy",
  transform: "translate(335.000000, 0.000000)"
}, createElement("g", {
  id: "icon_Open_A_New_Account",
  transform: "translate(0.000000, 3.000000)"
}, createElement("path", {
  d: "M11.9243667,6.65217391 L19.0666667,6.65217391 L19.0666667,5.91304348 L11.9243667,5.91304348 L11.9243667,6.65217391 Z M11.9243667,8.86956522 L19.0666667,8.86956522 L19.0666667,8.13043478 L11.9243667,8.13043478 L11.9243667,8.86956522 Z M11.9243667,11.0869565 L19.0666667,11.0869565 L19.0666667,10.3478261 L11.9243667,10.3478261 L11.9243667,11.0869565 Z M10.2666667,10.7173913 C10.2666667,10.9213913 10.1024,11.0869565 9.9,11.0869565 L2.56666667,11.0869565 C2.36426667,11.0869565 2.2,10.9213913 2.2,10.7173913 L2.2,9.12678261 C2.2,9.0428913 2.22823333,8.96158696 2.2803,8.89580435 C2.32613333,8.83852174 3.35536667,7.56684783 5.13333333,7.40756522 L5.13333333,6.88130435 C4.774,6.66547826 4.16093333,6.12258696 4.16093333,4.93295652 C4.16093333,3.99019565 4.16093333,2.69930435 6.3767,2.69930435 C8.59283333,2.69930435 8.59283333,3.99019565 8.59283333,4.93295652 C8.59283333,6.10632609 8.0344,6.65069565 7.7,6.87132609 L7.7,7.40793478 C9.4776,7.57165217 10.1926,8.89395652 10.2241333,8.95382609 C10.252,9.00704348 10.2666667,9.06654348 10.2666667,9.12678261 L10.2666667,10.7173913 Z M11.9243667,4.43478261 L15.4,4.43478261 L15.4,3.69565217 L11.9243667,3.69565217 L11.9243667,4.43478261 Z M21.6333333,0 L0.366666667,0 C0.164266667,0 0,0.165565217 0,0.369565217 L0,16.6304348 C0,16.8344348 0.164266667,17 0.366666667,17 L4.03333333,17 C4.23573333,17 4.4,16.8344348 4.4,16.6304348 L4.4,15.5217391 C4.4,14.6236957 4.97566667,14.0434783 5.86666667,14.0434783 C6.75766667,14.0434783 7.33333333,14.6236957 7.33333333,15.5217391 L7.33333333,16.6304348 C7.33333333,16.8344348 7.4976,17 7.7,17 L14.3,17 C14.5024,17 14.6666667,16.8344348 14.6666667,16.6304348 L14.6666667,15.5217391 C14.6666667,14.6236957 15.2423333,14.0434783 16.1333333,14.0434783 C17.0243333,14.0434783 17.6,14.6236957 17.6,15.5217391 L17.6,16.6304348 C17.6,16.8344348 17.7642667,17 17.9666667,17 L21.6333333,17 C21.8357333,17 22,16.8344348 22,16.6304348 L22,0.369565217 C22,0.165565217 21.8357333,0 21.6333333,0 L21.6333333,0 Z"
})))))));