function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import __NS_DYNAMIC_REACT_CONTENT___0 from "./footer-links.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { Body, LinkContainer as LinkConatainerBody, Helper } from 'legos/typography';
import { SystemIcon } from 'legos/system-icon';
import { createElement, Fragment, useRef } from 'react';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { LinksCta, LinkIcon, LinkCta, LinksContainer, LinkContainer, AppLinksContainer, AppStoreBadges, OverrideLinkBodyColor } from './styles/footer-links.ccm.css';
import { IconCard, IconPhone, IconRegister, IconPin, IconNewAccount } from './icons';
import { useLocation, useAnchor } from 'packages/react-nano-router';
import { useTheme } from '../../unauthed-layout/theme';
import { createOpenWithInAppBrowserAnchor } from 'apps/acp/packages/cordova-in-app-browser';
import { useSendAnalytics } from 'packages/react-analytics';
import { GA_ACTIONS_EVENT, GA_CATEGORY, GA_LABEL } from './analytics';
import { useHttpQuery } from 'packages/http-client/react';
import { AppleAppStoreBadgeLink, GooglePlayStoreBadgeLink } from 'apps/acp/packages/app-store-badges';
import { useAcquireNewCustomer } from 'apps/acp/packages/acquire-new-customer';
import { usePermissions } from '../permissions';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
const ColorLinkBody = OverrideLinkBodyColor.as(LinkConatainerBody);

const shouldShowActivateLink = (currentUrl, activateCardLinks, registerLinks) => {
  return currentUrl !== getLinkPath(activateCardLinks.main.url()) && currentUrl !== getLinkPath(registerLinks.main.url());
};

const getLinkPath = link => {
  return '/'.concat(link.split('/')[1].concat('/', link.split('/')[4]));
};

export const AcpFooterLinks = ({
  programType,
  showRegisterLink,
  showMarketingSiteLink,
  marketingSiteUrl,
  marketingSiteLinkText,
  contactLinks,
  activateCardLinks,
  registerLinks,
  locationFinderLinks,
  logoutLinks,
  loginLinks,
  showReloadLocationLink,
  atmFinderLinks,
  showFreeAtmFinderLink,
  showAcquisitionLink,
  openAccountFooterContent,
  gaCategory,
  gaLabel,
  clearSessionStorage
}) => {
  const {
    isShowOpenNewAccountButtonBgChanged
  } = usePermissions();
  const currentUrl = useLocation().pathname;
  const {
    lightBackgroundColor
  } = useTheme();
  const marketingSiteAnchor = createOpenWithInAppBrowserAnchor(marketingSiteUrl);
  const activateCardLinkAnchor = useAnchor(activateCardLinks.main);
  const registerOnlineAnchor = useAnchor(registerLinks.main);
  const activateAnchor = useAnchor(activateCardLinks.main);
  const contactAnchor = useAnchor(contactLinks.main);
  const reloadLocationAnchor = useAnchor(locationFinderLinks.main);
  const logoutAnchor = useAnchor(logoutLinks.main);
  const atmLocationAnchor = useAnchor(atmFinderLinks.main);
  const sendAnalytics = useSendAnalytics();
  const divRef = useRef(null);

  const executeScroll = () => divRef.current.scrollIntoView();

  const {
    overrideLinkColor
  } = useTheme();
  const AcquireNewCustomer = useAcquireNewCustomer();
  return createElement("div", {
    ref: divRef
  }, createElement(LinksCta.div, {
    $backgroundColor: lightBackgroundColor
  }, createElement(LinkCta.span, null, createElement(Helper, null, createElement(Content, {
    hash: "02a4d203ccc5ef0d7938fe01b30f967d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement("span", {
    onClick: executeScroll
  }, createElement(SystemIcon, {
    type: "chevronDown",
    color: "primary"
  }))), createElement(LinksContainer.div, null, currentUrl !== getLinkPath(loginLinks.main.url()) && createElement(LinkContainer.div, null, createElement(LinkIcon.span, null, createElement(IconCard, null)), createElement(ColorLinkBody, {
    $color: overrideLinkColor,
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_LOGIN_LINK,
        payload: {
          category: GA_CATEGORY.LOGIN,
          label: GA_LABEL.ACP_LOGIN
        }
      });
      clearSessionStorage && sessionStorage.clear();
      logoutAnchor.onClick();
    }
  }, createElement(Content, {
    hash: "4e8d8b762f9b5ea54bdd0371225242e9",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), shouldShowActivateLink(currentUrl, activateCardLinks, registerLinks) && createElement(LinkContainer.div, {
    "data-testid": "activateCard"
  }, createElement(LinkIcon.span, null, createElement(IconCard, null)), createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, activateAnchor, {
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_ACTIVATE_CARD_LINK,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });

      if (clearSessionStorage) {
        sessionStorage.clear();
        activateCardLinkAnchor.onClick();
      }
    }
  }), createElement(Content, {
    hash: "69c1278d8c872db2f0a3df16414fd844",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), shouldShowActivateLink(currentUrl, activateCardLinks, registerLinks) && showRegisterLink && currentUrl === getLinkPath(loginLinks.main.url()) && createElement(LinkContainer.div, {
    "data-testid": "registerOnline"
  }, createElement(LinkIcon.span, null, createElement(IconRegister, null)), createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, registerOnlineAnchor, {
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_REGISTER_ACCESS_LINK,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });

      if (clearSessionStorage) {
        sessionStorage.clear();
        registerOnlineAnchor.onClick();
      }
    }
  }), createElement(Content, {
    hash: "1d2cc16d422c44259efe5b83f172037d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), showReloadLocationLink && currentUrl !== getLinkPath(locationFinderLinks.main.url()) && createElement(LinkContainer.div, {
    "data-testid": "reloadLocations"
  }, createElement(LinkIcon.span, null, createElement(IconPin, null)), createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, reloadLocationAnchor, {
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_RELOAD_LOCATIONS,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });

      if (clearSessionStorage) {
        sessionStorage.clear();
        reloadLocationAnchor.onClick();
      }
    }
  }), createElement(Content, {
    hash: "c20c265744fd317651696f8d33f44cb6",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), showFreeAtmFinderLink && currentUrl !== getLinkPath(atmFinderLinks.main.url()) && createElement(LinkContainer.div, {
    "data-testid": "atmFinder"
  }, createElement(LinkIcon.span, null, createElement(IconPin, null)), createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, atmLocationAnchor, {
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_ATM_FINDER,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });

      if (clearSessionStorage) {
        sessionStorage.clear();
        atmLocationAnchor.onClick();
      }
    }
  }), createElement(Content, {
    hash: "1b7d2308d9f123c3103ff66d97bb625a",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), showAcquisitionLink && !isShowOpenNewAccountButtonBgChanged && currentUrl !== getLinkPath(loginLinks.main.url()) && createElement(LinkContainer.div, null, createElement(LinkIcon.span, null, createElement(IconNewAccount, null)), createElement(AcquireNewCustomer, {
    pageName: "unauth_footer"
  }, acquisitionUrlAnchor => createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor,
    target: "_blank"
  }, acquisitionUrlAnchor, {
    onClick: event => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_NEW_ACCOUNT_LINK,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });
      acquisitionUrlAnchor.onClick(event);
    }
  }), openAccountFooterContent || createElement(Content, {
    hash: "75ece3a433cdd6ea9abc8ad49c00c31f",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), currentUrl !== getLinkPath(contactLinks.main.url()) && createElement(LinkContainer.div, {
    "data-testid": "contactUs"
  }, createElement(LinkIcon.span, null, createElement(IconPhone, null)), createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, contactAnchor, {
    onClick: () => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_CONTACT_US_LINK,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });

      if (clearSessionStorage) {
        sessionStorage.clear();
        contactAnchor.onClick();
      }
    }
  }), createElement(Content, {
    hash: "44a3a8763c0feac28367ff8dc3724495",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), showMarketingSiteLink && createElement(LinkContainer.div, null, createElement(ColorLinkBody, _extends({
    $color: overrideLinkColor
  }, marketingSiteAnchor, {
    onClick: event => {
      gaCategory && gaLabel && sendAnalytics({
        event: GA_ACTIONS_EVENT.CLICKED_MARKETING_SITE_LINK,
        payload: {
          category: gaCategory,
          label: gaLabel
        }
      });
      marketingSiteAnchor.onClick(event);
    }
  }), marketingSiteLinkText))), createElement(AppLinks, {
    programType: programType
  }));
};

const AppLinks = ({
  programType
}) => {
  const {
    brand
  } = useHttpQuery(createUnAuthedGetBrandingRequest());
  const {
    lightBackgroundColor
  } = useTheme();
  const showAndroidLink = showMobileAppsSection(programType, brand.android_app_url);
  const showiOSLink = showMobileAppsSection(programType, brand.ios_app_url);
  return createElement(Fragment, null, (!showAndroidLink || !showiOSLink) && createElement(AppLinksContainer.div, {
    $backgroundColor: lightBackgroundColor
  }, createElement(Body, null, createElement(Content, {
    hash: "61e177dfbc31f5e4fa77b46afd8c6857",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(AppStoreBadges.div, null, brand.ios_app_url && createElement(AppleAppStoreBadgeLink, {
    appLink: brand.ios_app_url
  }), brand.android_app_url && createElement(GooglePlayStoreBadgeLink, {
    appLink: brand.android_app_url
  })), createElement(Helper, null, createElement(Content, {
    hash: "2deb7981f8cb635bb341880f67601a8d",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))));
};