export enum GA_ACTIONS_EVENT {
  //Actions Added for Login footer page events
  CLICKED_ACTIVATE_CARD_LINK = 'clicked the activate a card link',
  CLICKED_REGISTER_ACCESS_LINK = 'clicked the register for online access link',
  CLICKED_CONTACT_US_LINK = 'clicked Contact Us link',
  CLICKED_MARKETING_SITE_LINK = 'clicked Marketing site link',
  CLICKED_RELOAD_LOCATIONS = 'clicked Reload Locations',
  CLICKED_LOGIN_LINK = 'clicked login link',
  CLICKED_ATM_FINDER = 'clicked free ATM finder',
  CLICKED_NEW_ACCOUNT_LINK = 'clicked the open a new account link'
}

export enum GA_CATEGORY {
  LOGIN = 'login'
}

export enum GA_LABEL {
  ACP_LOGIN = 'acp-login'
}
