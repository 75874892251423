import { createUseThemeHook } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    acpUnAuthLayout: Partial<AcpUnAuthLayoutTheme>;
  }
}

export interface AcpUnAuthLayoutTheme {
  appBarBorderBottomColor: string;
  appBarWhiteBackgroundColor: string;
  appBarDefaultBackgroundColor: string;
  layoutWhiteBackgroundColor: string;
  layoutGreyBackgroundColor: string;
  overrideLinkColor: string;
  lightBackgroundColor: string;
}

const [AcpUnAuthLayoutThemeProvider, useTheme] = createUseThemeHook(
  'acpUnAuthLayout',
  (global): AcpUnAuthLayoutTheme => ({
    appBarBorderBottomColor: global.neutral.color300,
    appBarWhiteBackgroundColor: '#FFF',
    appBarDefaultBackgroundColor: global.default.color500,
    layoutWhiteBackgroundColor: '#FFF',
    layoutGreyBackgroundColor: global.neutral.color100,
    overrideLinkColor: global.default.color500,
    lightBackgroundColor: global.neutral.color100
  })
);

export { AcpUnAuthLayoutThemeProvider, useTheme };
