
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.StyledLayout_4207cb707b074bc2{margin:43px 0 0}.LayoutBody_a168018c9c91530c,.StyledLayout_4207cb707b074bc2{background-color:var(--backgroundColor_03b01dd58b4fcdfe)}.MainSection_b16c65f01c95c5f3{padding:0;width:100%;margin-top:0;margin-bottom:0}@media (min-width:600px){.LayoutBody_a168018c9c91530c{width:664px;max-width:664px;margin:64px auto}}`;
styleInject(_css)

/** StyledLayout Props */
export type StyledLayoutCCM = {
  /** StyledLayout Component Custom Properties */
  '$backgroundColor': string;

  /** StyledLayout Modifier Flags */
  // No modifiers classes found
};
/** Base StyledLayout component */
export const StyledLayout: ComponentCreator<StyledLayoutCCM> = createComponentCreator({
  "name": "StyledLayout",
  "base": "StyledLayout_4207cb707b074bc2",
  "prop": {
    "$backgroundColor": "--backgroundColor_03b01dd58b4fcdfe"
  },
  "mod": {}
});


/** LayoutBody Props */
export type LayoutBodyCCM = {
  /** LayoutBody Component Custom Properties */
  '$backgroundColor': string;

  /** LayoutBody Modifier Flags */
  // No modifiers classes found
};
/** Base LayoutBody component */
export const LayoutBody: ComponentCreator<LayoutBodyCCM> = createComponentCreator({
  "name": "LayoutBody",
  "base": "LayoutBody_a168018c9c91530c",
  "prop": {
    "$backgroundColor": "--backgroundColor_03b01dd58b4fcdfe"
  },
  "mod": {}
});


/** MainSection Props */
export type MainSectionCCM = {
  /** MainSection Component Custom Properties */
  // No custom properties found

  /** MainSection Modifier Flags */
  // No modifiers classes found
};
/** Base MainSection component */
export const MainSection: ComponentCreator<MainSectionCCM> = createComponentCreator({
  "name": "MainSection",
  "base": "MainSection_b16c65f01c95c5f3",
  "prop": {},
  "mod": {}
});

