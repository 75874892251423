
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.AppLinksContainer_7c727ad98f127e56,.AppStoreBadges_91558eb40e192031,.LinksContainer_38a6486b7103e822,.LinksCta_aa43dce3df5671e1{display:flex;flex-direction:column;align-items:center;justify-content:center}.LinksCta_aa43dce3df5671e1{padding:4px 8px 8px;background-color:var(--backgroundColor_15958563af0ec905)}.LinkCta_780fce28ae651ddc{margin-bottom:-16px}.LinksContainer_38a6486b7103e822{padding:26px 8px 14px;box-sizing:border-box;min-height:80px;background-color:#fff}.LinkContainer_3cb895113e021017{display:flex;flex-direction:row;align-items:center;flex-wrap:wrap;justify-content:center;min-width:150px}.LinkIcon_291d784b82f45d2c{display:inline-block;margin-bottom:16px;padding:0 7px}.AppLinksContainer_7c727ad98f127e56{padding:16px 0 0;background-color:var(--backgroundColor_15958563af0ec905)}.AppLinksContainer_7c727ad98f127e56.hide_42f0a89829fbc2b2{display:none}.OverrideLinkBodyColor_21f0ba78aff82bf8,.OverrideLinkBodyColor_21f0ba78aff82bf8:hover{margin-bottom:16px;color:var(--color_32679163f9e4a3f0)}.ActivateCardBlock_0d6393e57b7eb2d5{width:100%}.ActivateCardLink_ef8079b1b50014a3{display:inline-block;margin-right:6px;margin-bottom:14px}@media (min-width:600px){.LinksCta_aa43dce3df5671e1{display:none}.LinksContainer_38a6486b7103e822{flex-direction:row;flex-wrap:wrap;justify-content:center;-moz-column-gap:80px;column-gap:80px;padding:20px 100px 0}.LinkIcon_291d784b82f45d2c{display:inline-block;margin-right:6px;margin-bottom:14px}.AppStoreBadges_91558eb40e192031{flex-direction:row}.AppLinksContainer_7c727ad98f127e56{padding:48px 0 16px}}@media (min-width:1280px){.LinksContainer_38a6486b7103e822{-moz-column-gap:160px;column-gap:160px}}`;
styleInject(_css)

/** LinksCta Props */
export type LinksCtaCCM = {
  /** LinksCta Component Custom Properties */
  '$backgroundColor': string;

  /** LinksCta Modifier Flags */
  // No modifiers classes found
};
/** Base LinksCta component */
export const LinksCta: ComponentCreator<LinksCtaCCM> = createComponentCreator({
  "name": "LinksCta",
  "base": "LinksCta_aa43dce3df5671e1",
  "prop": {
    "$backgroundColor": "--backgroundColor_15958563af0ec905"
  },
  "mod": {}
});


/** LinksContainer Props */
export type LinksContainerCCM = {
  /** LinksContainer Component Custom Properties */
  // No custom properties found

  /** LinksContainer Modifier Flags */
  // No modifiers classes found
};
/** Base LinksContainer component */
export const LinksContainer: ComponentCreator<LinksContainerCCM> = createComponentCreator({
  "name": "LinksContainer",
  "base": "LinksContainer_38a6486b7103e822",
  "prop": {},
  "mod": {}
});


/** AppLinksContainer Props */
export type AppLinksContainerCCM = {
  /** AppLinksContainer Component Custom Properties */
  '$backgroundColor': string;

  /** AppLinksContainer Modifier Flags */
  '$hide'?: boolean;
};
/** Base AppLinksContainer component */
export const AppLinksContainer: ComponentCreator<AppLinksContainerCCM> = createComponentCreator({
  "name": "AppLinksContainer",
  "base": "AppLinksContainer_7c727ad98f127e56",
  "prop": {
    "$backgroundColor": "--backgroundColor_15958563af0ec905"
  },
  "mod": {
    "$hide": "hide_42f0a89829fbc2b2"
  }
});


/** AppStoreBadges Props */
export type AppStoreBadgesCCM = {
  /** AppStoreBadges Component Custom Properties */
  // No custom properties found

  /** AppStoreBadges Modifier Flags */
  // No modifiers classes found
};
/** Base AppStoreBadges component */
export const AppStoreBadges: ComponentCreator<AppStoreBadgesCCM> = createComponentCreator({
  "name": "AppStoreBadges",
  "base": "AppStoreBadges_91558eb40e192031",
  "prop": {},
  "mod": {}
});


/** LinkCta Props */
export type LinkCtaCCM = {
  /** LinkCta Component Custom Properties */
  // No custom properties found

  /** LinkCta Modifier Flags */
  // No modifiers classes found
};
/** Base LinkCta component */
export const LinkCta: ComponentCreator<LinkCtaCCM> = createComponentCreator({
  "name": "LinkCta",
  "base": "LinkCta_780fce28ae651ddc",
  "prop": {},
  "mod": {}
});


/** LinkContainer Props */
export type LinkContainerCCM = {
  /** LinkContainer Component Custom Properties */
  // No custom properties found

  /** LinkContainer Modifier Flags */
  // No modifiers classes found
};
/** Base LinkContainer component */
export const LinkContainer: ComponentCreator<LinkContainerCCM> = createComponentCreator({
  "name": "LinkContainer",
  "base": "LinkContainer_3cb895113e021017",
  "prop": {},
  "mod": {}
});


/** LinkIcon Props */
export type LinkIconCCM = {
  /** LinkIcon Component Custom Properties */
  // No custom properties found

  /** LinkIcon Modifier Flags */
  // No modifiers classes found
};
/** Base LinkIcon component */
export const LinkIcon: ComponentCreator<LinkIconCCM> = createComponentCreator({
  "name": "LinkIcon",
  "base": "LinkIcon_291d784b82f45d2c",
  "prop": {},
  "mod": {}
});


/** OverrideLinkBodyColor Props */
export type OverrideLinkBodyColorCCM = {
  /** OverrideLinkBodyColor Component Custom Properties */
  '$color': string;

  /** OverrideLinkBodyColor Modifier Flags */
  // No modifiers classes found
};
/** Base OverrideLinkBodyColor component */
export const OverrideLinkBodyColor: ComponentCreator<OverrideLinkBodyColorCCM> = createComponentCreator({
  "name": "OverrideLinkBodyColor",
  "base": "OverrideLinkBodyColor_21f0ba78aff82bf8",
  "prop": {
    "$color": "--color_32679163f9e4a3f0"
  },
  "mod": {}
});


/** ActivateCardBlock Props */
export type ActivateCardBlockCCM = {
  /** ActivateCardBlock Component Custom Properties */
  // No custom properties found

  /** ActivateCardBlock Modifier Flags */
  // No modifiers classes found
};
/** Base ActivateCardBlock component */
export const ActivateCardBlock: ComponentCreator<ActivateCardBlockCCM> = createComponentCreator({
  "name": "ActivateCardBlock",
  "base": "ActivateCardBlock_0d6393e57b7eb2d5",
  "prop": {},
  "mod": {}
});


/** ActivateCardLink Props */
export type ActivateCardLinkCCM = {
  /** ActivateCardLink Component Custom Properties */
  // No custom properties found

  /** ActivateCardLink Modifier Flags */
  // No modifiers classes found
};
/** Base ActivateCardLink component */
export const ActivateCardLink: ComponentCreator<ActivateCardLinkCCM> = createComponentCreator({
  "name": "ActivateCardLink",
  "base": "ActivateCardLink_ef8079b1b50014a3",
  "prop": {},
  "mod": {}
});

